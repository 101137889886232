@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .cb {
        @apply border-[1px] border-[#f00]
    }
    .menuLi {
        @apply text-c_gray-regular cursor-pointer font-otf-semiBold not-italic
        ms:text-[14px] ms:leading-[14px]
        text-[26px] leading-[26px]
    }
    .buttonMenu {
        @apply flex justify-center items-center rounded-[6px]
        bg-c_orange-regular hover:bg-c_orange-hover active:bg-c_orange-pressed
        ms:w-[135px] ms:h-[37px]
        w-[111px] h-[30px]
    }
    .buttonText {
        @apply text-c_white-button cursor-pointer not-italic font-otf-book
        ms:menuLi
    }
    .menuUl {
        @apply 
        ms:w-[416px] ms:h-[37px]
        w-full h-[100vh] absolute text-center bg-c_purple-header left-0
        ms:flex ms:justify-between ms:items-center ms:bg-none ms:relative ms:top-0 ms:text-left
    }
    .startPageText {
        @apply not-italic font-otf-semiBold
        md:text-[58px] md:leading-[55px]
        text-[26px] leading-[26px]

    }
    .startPageTheLoop {
        @apply absolute w-[1280px] h-full z-[1]
        xl:top-[-70px] xl:right-[-30px]
        md:top-[-80px] md:right-[-110px]
        s:top-[-30px] s:right-[-370px] 
        top-[-26px] right-[-480px]
        bg-center bg-no-repeat bg-contain
        bg-startPageTheLoop
    }
    .startProjects {
        @apply absolute w-full h-full
        xl:bg-contain bg-cover bg-top bg-no-repeat 
        bottom-0 right-0
        bg-startProjects s:startRadius startRadiusS
    }
    .trapezoidCorner {
        @apply absolute z-[2] bg-c_gray-trapezoid
        trapezoid s:top-0 s:left-0 top-0 left-0 
        s:w-[80px] s:h-[80px] w-[40px] h-[40px]
    }
    .pageTitle {
        @apply not-italic font-otf-semiBold
        md:text-[60px] md:leading-[57.9px]
        text-[26px] leading-[25px]
    }
    .ourProjectsTheLoop {
        @apply absolute
        md:w-[1280px] md:h-[395px]
        w-[602px] h-[176px]
        md:top-[-100px] xs:left-[-170px]
        md:left-[-190px]
        top-[-60px] left-[-130px]
        bg-center bg-no-repeat bg-contain
        bg-ourProjectsTheLoop
    }
    .ourProjectStyleTitle {
        @apply not-italic font-otf-semiBold text-c_blue-dark
        md:text-[45px] md:leading-[43px] md:p-0
        text-[23px] leading-[23px] pb-[10px]
    }
    .ourProjectStyleSubTitle {
        @apply font-otf-semiBold not-italic  text-c_blue-deepDark
        md:text-[18px] md:leading-[21.6px]
        text-[15px] leading-[18px] xs:w-[500px] w-[320px] 
    }
    .iconMedia {
        @apply flex justify-center items-center
        xl:mx-[35px]
        md:w-[158px] md:h-[65px] 
        w-[84px] h-[31px]
    }
    .wrapperLine {
        @apply absolute z-10
    }
    .worksTextStyle {
        @apply not-italic font-otf-book text-c_blue-deepDarkOp
        md:text-[18px] md:leading-[21.6px] md:h-max
        text-[15px] leading-[18px]
    }
    .moreAcceleratorTheLoop {
        @apply absolute bg-center bg-no-repeat bg-contain 
        md:h-[312px] md:w-[1200px]
        h-[109px] w-[498px]
        md:bottom-[5] bottom-[10px] md:left-[-50px] left-[-34px]
        bg-moreAcceleratorLoop
    }
    .contactText {
        @apply text-c_blue-cont font-otf-book not-italic
        md:text-[50px] md:leading-[53px] ms:max-w-[870px] ms:pb-[75px]
        text-[23px] leading-[31px]  w-[90vw] pb-[30px]
    }
    .contactLink {
        @apply opacity-[1] text-c_orange-regular duration-500
        hover:cursor-pointer hover:opacity-70
        active:cursor-pointer active:opacity-70
        border-b-[1px] border-b-[#857a714d] 
    }

}    

@font-face {
    font-family: 'otf-semiBold';
    src: url("/public/fonts/otf/SuisseIntl-SemiBold.otf");
    font-display: swap;
}
@font-face {
    font-family: 'otf-normal';
    src: url("../public/fonts/otf/SuisseIntl-Regular.otf");
    font-display: swap;
}
@font-face {
    font-family: 'otf-book';
    src: url("../public/fonts/otf/SuisseIntl-Book.otf");
    font-display: swap;
}
@font-face {
    font-family: 'otf-bold';
    src: url("../public/fonts/otf/SuisseIntl-Bold.otf");
    font-display: swap;
}
@font-face {
    font-family: 'otf-light';
    src: url("../public/fonts/otf/SuisseIntl-Light.otf");
    font-display: swap;
}

@layer utilities{
    .trapezoid {
        clip-path: polygon(0 45%, 45% 0, 100% 0, 0% 100%);
    }
    .gradientBackGround {
        background: 
            linear-gradient(181.04deg, #2E2B59 0.89%, #32476F 50.61%, #4B3882 93.94%), 
            linear-gradient(180.43deg, #2E2B59 37.77%, #3991B7 60.04%, #B97333 78.65%);
    }
    .startRadius {
        border-radius: 29px 0px 0px 0px;
    }
    .startRadiusS {
        border-radius: 19px 0px 0px 0px;    
    }
    .photoRadius {
        border-radius: 10px 60px;
    }
    .cardRadius {
        border-radius: 4px 25px;
    }
    .photoCardRadius {
        border-radius: 2px 14px;
    }
    .last_letter::after {
        content: "→" ;
        color: rgba(244, 68, 13, 1);
        padding-left: 20px;
    }

    .flex_features {
        flex: 0 1 auto;
    }
}